@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";
@import "/src/index.css";

.footer {
  margin-left: auto;
  margin-right: auto;
  margin-top: 150px;
  border-radius: 25px 25px 0 0;
  max-width: 1440px;
  min-height: 305px;
  background: var(--background-color);
}

.container {
  padding: 50px 75px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.containerLogo {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.containerLogo .logoText {
  max-width: 230px;
  font-size: 1.5em;
  line-height: 1;
  font-weight: 500;
  color: var(--secondary-color);
}

.containerLogo .logoActions {
  margin-top: 70px;
  display: flex;
  gap: 30px;
}

.containerLink ul,
.containerLinkNav ul {
  list-style-type: none;
  margin: 0;
}

.containerLink ul > li > a,
.containerLinkNav ul > li > a {
  text-decoration: none;
  color: var(--link-color);
  font-size: 0.875em;
  line-height: 1.5;
  font-weight: 600;
}

.containerLink ul > li,
.containerLinkNav ul > li {
  padding-bottom: 16px;
}

.containerLink ul > li:last-child {
  margin-top: 90px;
}

.containerLinkNav ul > li:last-child {
  margin-top: 53px;
}

.containerAddress {
  width: 260px;
  height: 155px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 0.875;
  line-height: 1.2;
  font-weight: 600;
  color: var(--link-color);
}

.containerSocial {
  display: flex;
  gap: 20px;
}
