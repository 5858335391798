@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";
@import "/src/index.css";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--secondary-color);
  font-weight: 500;
  font-size: 2.25em;
}

.haveSend {
  margin-top: 50px;
  color: var(--link-color);
}

.forgot_password {
  display: flex;
  justify-content: flex-end;
}

.forgot_password a {
  text-decoration: none;
  color: var(--secondary-color);
  margin-top: 5px;
  font-size: 0.75em;
}

.warning {
  font-size: 0.75em;
  color: var(--warning-color);
}

.checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  color: var(--secondary-color);
  font-size: 0.875em;
}

.test svg {
  fill: var(--error-color);
}

.errorMessage {
  margin-left: 5px;
  color: var(--error-color);
  font-size: 12px;
  margin-top: 4px;
}

.inscriptionSpam {
  margin-left: 5px;
}

.privacy {
  margin-top: 10px;
}

.privacy a {
  text-decoration: none;
  color: var(--secondary-color);
  font-size: 0.875em;
}

.loginOr {
  margin-top: 36px;
  color: var(--secondary-color);
  font-size: 0.875em;
}

.google img {
  margin-top: 20px;
  padding: 5px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
}

.loginCreateAccount {
  display: flex;
  margin-top: 32px;
  color: var(--secondary-color);
  font-size: 0.875em;
}

.loginCreateAccount a {
  text-decoration: none;
  font-weight: 600;
  color: inherit;
}

.accountLink {
  margin-left: 5px;
}

.resendCode {
  margin-top: 32px;
  color: var(--link-color);
  font-size: 0.875em;
}

.resendCode a {
  text-decoration: none;
  color: var(--link-color);
}

.noSpinner input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.noSpinner input[type="number"]::-webkit-outer-spin-button,
.noSpinner input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, and Opera */
  margin: 0;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Add this property */
}

.loginFormOverlay {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  pointer-events: auto; /* Add this property */
}
