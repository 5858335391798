@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";

.header {
  padding: 20px 0;
}

.container {
  background: var(--background-color);
  color: var(--secondary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 25px;
  border-radius: 61px;
}

.containerLogoNav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerLogo {
  width: 184.25px;
  height: 20px;
  gap: 3px;
}

.headerNavLink ul {
  display: flex;
  gap: 40px;
  color: var(--link-color);
  font-size: 0.875em;
  font-weight: 600;
  line-height: 1.5;
}

.headerActions,
.headerLang,
.headerProfile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerActions {
  gap: 40px;
}

.headerLang span {
  color: var(--link-color);
  font-size: 0.875em;
  line-height: 1.5;
  font-weight: 600;
}

.headerProfile {
  border: none;
  background: var(--background-color);
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5;
  color: var(--secondary-color);
}

@media (max-width: 768px) {
}
