:root {
  --background-color: linear-gradient(180deg, #ffffff 0%, #eceff1 100%);
  --background-color-body: linear-gradient(
    180deg,
    rgba(0, 53, 136, 0.9),
    rgba(236, 239, 241, 0.9) 15%,
    rgba(236, 239, 241, 0.9) 30%,
    rgba(0, 53, 136, 0.9) 60%,
    rgba(236, 239, 241, 0.9) 75%,
    rgba(0, 53, 136, 0.9) 100%
  );

  --primary-color: #0d47a1;
  --secondary-color: #1a1a1a;
  --text-stroke-color: #f7ef8a;
  --text-shadow-color: #084fbd73;

  --link-color: #4e4e4e;

  --border-color: #d0d0d0;

  --warning-color: #c6082b;

  --error-color: #d32f2f;

  --title-color: #fff;
  --number-color: #000000;
  --card2-color: #6585b7;
  --card3-color: #3375d9;
  --boxText-color: #ececec;
}
