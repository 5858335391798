body {
  max-width: 1600px;
  margin: 0 auto;
  font-family: Montserrat, sans-serif;
  line-height: 1.22;
  background: var(--background-color-body);
}

ul,
ol,
li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 4em;
  font-weight: 600;
  color: var(--title-color);
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 500;
  margin-top: 150px;
  color: var(--secondary-color);
}
