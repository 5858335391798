@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.numberError,
.notFound,
.errorMessage {
  color: var(--secondary-color);
}

.numberError {
  font-family: roboto, sana-serif;
  font-weight: 600;
  font-size: 9.375em;
  color: var(--primary-color);
  text-shadow: 3px 5px 8.8px var(--text-shadow-color);
  -webkit-text-stroke: 2px var(--text-stroke-color);
}

.notFound {
  font-weight: 500;
  font-size: 2.25em;
}

.errorMessage {
  text-align: center;
}
