@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";

@import "/src/index.css";
@import "/src/colors.variables.css";

.accordion {
  margin: 0 auto;
  max-width: 1290px;
}

.accordionItem {
  margin-bottom: 20px;
  background: var(--background-color);
  border: 1px solid var(--text-stroke-color);
  border-radius: 5px;
}

.titleMain {
  font-size: 3em;
  font-weight: 500;
  text-align: left;
  padding-bottom: 30px;
  text-transform: uppercase;
  color: var(--secondary-color);
}

.buttonGroup {
  display: flex;
  margin-bottom: 40px;
  gap: 20px;
}

.MuiAccordion-root {
  max-height: 50px;
  transition: max-height 0.3s ease-in-out;
  background-color: inherit;
  background: var(--background-color);
}

.MuiAccordion-root.Mui-expanded {
  max-height: 200px;
  background-color: var(--text-stroke-color);
}

.MuiAccordionDetails-root {
  display: none;
}

.MuiAccordionDetails-root.Mui-expanded {
  display: block;
}
