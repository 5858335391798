@import "../../styles_theme/light.css";
@import "../../styles_theme/dark.css";
@import "/src/index.css";
@import "/src/colors.variables.css";

/* main.js averageCard.js */

.wrapper {
  max-width: 1290px;
  margin: 0 auto;
}

.container {
  display: flex;
}

.textUnderTitile {
  width: 410px;
  font-size: 1.75em;
  font-weight: 500;
  color: var(--title-color);
}

.containerAvarageCard {
  padding-top: 90px;
}

.averageCard {
  position: absolute;
  top: 0;
  left: 0;
  height: 365px;
  width: 513px;
  margin: 16px;
  gap: 16px;
  background: var(--background-color);
  border: 1px solid var(--text-stroke-color);
  border-radius: 10px;
  box-shadow: 0px 18px 40px 0px var(--text-shadow-color);
  transition: transform 0.5s ease-in-out;
}

.averageNumber {
  font-size: 3em;
  font-weight: 600;
  color: var(--number-color);
  margin-top: 15px;
  margin-left: 20px;
}

.averageDescription {
  font-size: 1.25em;
  font-weight: 500;
  color: var(--link-color);
  margin-top: 25px;
  margin-left: 20px;
}

.averageIconSvg {
  margin-top: 70px;
}

.boxCard {
  position: absolute;
  top: 160px;
  right: 170px;
  height: 65px;
  width: 79px;
  border-radius: 10px;
}

.boxText {
  padding-top: 10px;
  text-align: center;
  font-size: 0.75em;
  font-weight: 500;
  color: var(--boxText-color);
}

.boxNum {
  padding: 10px 12px;
  font-weight: 500;
  color: var(--title-color);
}

/* investCard.js */

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 225px;
  width: 400px;
  border: 2px solid #f7ef8a;
  border-radius: 10px;
  background: var(--background-color);
  box-shadow: 1px 4px 20px 0px var(--text-shadow-color);
}

.cardNum {
  font-size: 4em;
  font-weight: 500;
  color: var(--link-color);
}

.cardText {
  margin-top: 10px;
  color: var(--link-color);
}

.cardInvestText {
  display: flex;
  align-items: left;
  max-width: 283px;
  font-size: 1.5em;
  color: var(--link-color);
}

/* chartsLines.js */

.chartsLinesTitle {
  max-width: 539px;
}

.containarLineChart {
  position: relative;
  min-height: 500px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 2px 20px 0px var(--text-shadow-color);
  border: 1px solid var(--text-stroke-color);
  background: var(--background-color);
  transition: opacity 0.3s ease-in-out;
}

.lineChart {
  min-height: 447px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.containarIcons {
  display: flex;
  padding-left: 20px;
  padding-top: 20px;
  gap: 30px;
}

.containarIconsImg {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(214, 219, 223, 0.5);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid var(--card3-color);
  background: rgba(214, 219, 223, 0.5);
  border-top-color: transparent;
  animation: spin 1s linear infinite;
  box-shadow: 0px 0px 30px 0px #2765c4;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loaderText {
  color: var(--secondary-color);
  margin-top: 10px;
}

/* understandTheMarket.js */

.understandMarketTitle {
  color: var(--title-color);
  font-size: 3em;
  font-weight: 500;
  max-width: 554px;
}

.understandMarketTitleUnderText {
  color: var(--title-color);
  font-size: 2.25em;
  font-weight: 500;
  margin-bottom: 40px;
}

.cardsContainerUnderstandMarket {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cardBoxUnderstandMarket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  height: 50px;
  width: 600px;
  border: 2px solid #f7ef8a;
  border-radius: 10px;
  background: var(--background-color);
  box-shadow: 1px 4px 20px 0px var(--text-shadow-color);
}

.cardTextUnderstandMarket {
  font-size: 1.5em;
  font-weight: 500;
  text-align: left;
  color: var(--link-color);
  margin-left: 20px;
}

/* historyLeader.js */

.historyOfTheLeader {
  max-width: 457px;
}

.historyOfTheLeaderUnderText {
  max-width: 625px;
  font-family: Ubuntu;
  font-size: 1.5em;
  margin-bottom: 15px;
  color: var(--link-color);
}

/* partners.js */

.containerPartnersImg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
